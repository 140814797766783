import { graphql } from 'gatsby';
import Page, { Head } from '../components/page'

export default Page;
export { Head };

export const query = graphql`
  query HomepageContent {
    site {
      ...SiteMetadata
    }
    wp {
      ...WpSeo
    }
    wpPage(slug: { eq: "home" }) {
      ...WpPageContent
    }
  }
`;
